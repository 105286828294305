.c {
  position: absolute;
  cursor: pointer;
  will-change: transform, opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* background-color: #d0b9a7; */
  /* border-radius: 8px; */
  background-image: url(https://storage.googleapis.com/pl-public-assets/TILE_SC_E-01.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding: 0;
}
