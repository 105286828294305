body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.active {
  color: #f44336;
}

@keyframes pulseScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.pulseIcon {
  animation: pulseScale 1s infinite;
}

/* .q-parent:hover .quiz-q-text {
  background-color: rgba(26, 179, 148, 0.2);
} */
/* antd */
.table-row-danger {
  background-color: #f44336;
}
